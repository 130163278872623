@import url('https://fonts.googleapis.com/css2?family=Bokor&family=Jacques+Francois+Shadow&family=Major+Mono+Display&family=Tiro+Kannada&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bokor&family=IBM+Plex+Mono&family=Jacques+Francois+Shadow&family=Major+Mono+Display&family=Tiro+Kannada&display=swap');

body, html {
    margin: 3px;
    padding: 0;
}

header, footer {
    width: 100%;
    box-sizing: border-box; 
}

body {
    font-family: 'Courier New', Courier, monospace;
    background: #fdf5e6;
    color: #000;
}

h2 {
    font-family: 'IBM Plex Mono', monospace;
}

.content {
    margin-left: 100px;
    padding: 30px;
    max-width: 869px; /* adjust as needed */
    margin: 0 auto; /* centers the content */
}

.content h2 {
    font-size: 30px;
    margin-bottom: 20px;
}

.content p {
    font-size: 18px;
    line-height: 1.6;
    text-align: left;
}



.thumbnail {
    display: inline-block;
    width: 420px;  /* or your desired width for thumbnail */
    height: 100%; 
    vertical-align: top;
}

.thumbnail img {
    width: 50%;
    height: auto;
}

.video-description {
    display: inline-block;
    font-size: 20px;
    width: calc(100% - 300px);  /* Total width minus thumbnail width and a little space */
    vertical-align: top;
    text-align: left;
    padding-left: 10px; /* Some space between thumbnail and description */
}

#about img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

#about h3 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 20px;
}
