/* src/assets/css/home.css */

#home {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Adjust spacing between text and image */
  align-items: center;
  padding: 20px;
}

.text-container {
  flex: 1;
  max-width: 45%; /* Allocate 45% of the width to the text container */
  margin-right: 20px; /* Add margin to separate text from image */
}

.text-container h2 {
  font-size: 28px;
  margin-bottom: 30px;
}

.links-container {
  display: flex;
  flex-direction: column;
}

.link-box {
  display: block;
  background-color: #b4a89b !important;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  transition: box-shadow 0.3s ease;
  text-decoration: none !important;
  color: #212121 !important; /* Ensure the text color is correct */
}

.link-box:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  text-decoration: none !important; /* Remove underline on hover */
}

.link-box h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: inherit !important; /* Inherit color from the parent */
}

.link-box p {
  font-size: 16px;
  color: inherit !important; /* Inherit color from the parent */
}

.link-box a {
  color: inherit !important; /* Ensure the link inherits the color */
  text-decoration: none !important;
}

.link-box a:hover {
  text-decoration: underline;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%; /* Allocate 50% of the width to the image container */
  padding: 20px;
}

.profile-image {
  width: 100%; /* Ensure the image takes up the full width of its container */
  max-width: 450px; /* Limit the maximum width of the image */
  height: auto;
  border-radius: 3px; /* Slightly increase the border-radius for a smoother look */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Add a soft shadow */
  border: 2px solid #aca49b; /* Match the border color with the site's theme */
}

/* Media query to hide the image on small screens */
@media screen and (max-width: 646px) {
  .image-container {
    display: none; /* Hide the image container on small screens */
  }

  .text-container {
    max-width: 100%; /* Use full width for the text on small screens */
    margin-right: 0; /* Remove the right margin */
  }
}
