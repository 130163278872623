/* src/assets/css/contact.css */

#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 800px;
  }
  
  #contact h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  label {
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
  }
  
  input, textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  input::placeholder, textarea::placeholder {
    color: #999;
  }
  
  textarea {
    resize: vertical;
    height: 100px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #b19e8b;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #9a8873;
  }
  