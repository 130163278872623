/* src/assets/css/footer.css */

footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #b4a89b;
    color: black;
    flex-wrap: wrap;
  }
  
  #about-rights {
    margin-top: 24px;
    margin-bottom: 10px;
  }
  
  footer div {
    display: flex;
    flex-direction: column;
  }
  
  footer a {
    color: black;
    text-decoration: none;
  }
  
  footer p {
    margin-top: 10px;
  }
  
  #social-media {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Center items on the horizontal line */
    justify-content: center;  /* Center items on the vertical line */
  }
  
  #podcasts a, #social-media a {
    display: flex;
    align-items: center;
    gap: 5px; /* Create space between the icon and the text */
    margin-bottom: 10px;
  }
  
  #social-media a {
    margin-right: 10px; /* Create space between the icons */
  }
  
  #about-rights a {
    margin-bottom: 10px;
  }
  