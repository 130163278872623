#sidenav-trigger {
  position: absolute;
  top: calc(144px + 10px); /* 10px buffer from the header */
  left: 6px;
  width: 50px; /* Default square size */
  height: 50px; /* Default square size */
  background-color: #b19e8b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: top 0.3s ease, width 0.3s ease; /* Smooth transition */
}

#sidenav-trigger.fixed {
  position: fixed;
  top: 10px; /* 10px buffer when fixed */
  left: 6px;
}

/* The actual sidenav (hidden by default) */
.sidenav {
  position: fixed;
  overflow-y: auto;
  height: 100%;
  width: 0;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #b19e8b;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px; /* Padding for larger screens */
  text-decoration: none;
  font-size: 25px;
  color: #000;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.social-media-links {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center; /* Centers the icons horizontally */
  align-items: center;     /* Centers the icons vertically */
}

.social-media-links a {
  color: #000;
  text-decoration: none;
}

.social-media-links a:hover {
  color: #f1f1f1;
}

/* Media query for smaller screens */
@media screen and (max-width: 796px) {
  #sidenav-trigger {
    position: fixed;
    top: calc(144px + 10px); /* 10px buffer from the header */
    left: 6px; /* Align to the left of the screen */
    width: 100%; /* Full width */
    height: 50px; /* Height of the top bar */
    background-color: #b4a89b;
    display: flex;
    justify-content: flex-start; /* Align the icon to the start */
    align-items: center;
    padding-left: 20px; /* Padding for the icon */
    padding-right: 50px; /* Padding on the right side */
    box-sizing: border-box; /* Include padding in width calculation */
    z-index: 3;
  }

  #sidenav-trigger span {
    display: inline;
    font-size: 30px;
  }

  #sidenav-trigger.fixed {
    position: fixed;
    left: 0px;
    top: 0px;
  }

  .sidenav a {
    padding: 5px; /* Padding for small screens */
    top: 50px;
  }

  #main-content {
    padding-top: 60px; /* Ensure content starts below the top bar */

  }
}