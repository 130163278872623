@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');

#writings {
    padding: 20px;
    /* Remove or comment out the fixed height */
    /* height: 800px; */
}

#writings h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.writings-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.writing-link-box {
    display: flex;
    align-items: center; /* Align items to the center */
    background-color: #b4a89b; /* Ensure background color */
    border-radius: 8px;
    padding: 20px;
    text-decoration: none;
    color: #000;
    transition: background-color 0.3s ease;
}

.writing-link-box:hover {
    background-color: #a18b7a; /* Slightly darker shade on hover */
}

.writing-thumbnail {
    flex-shrink: 0;
    margin-right: 20px;
    width: 150px; /* Adjust the width as needed */
}

.writing-thumbnail img {
    border-radius: 8px;
    width: 100%;
    height: auto;
}

.writing-description {
    flex: 1;
    font-size: 18px;
    white-space: normal; /* Allow text to wrap */
    overflow: visible; /* Ensure text is fully visible */
}

.writing-description h3 {
    margin: 0 0 10px;
    font-size: 1.5em;
}

.writing-description p {
    margin: 0;
    font-size: 1em;
    line-height: 1.5;
}

/* Apply Noto Sans Mono font to the markdown content */
.markdown-content {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 16px; 
  line-height: 1.6; 
  color: #000; 
  background-color: #ddd5cc; 
  padding: 20px; 
  border-radius: 8px; 
  text-align: justify; /* Right justify the text */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* Optional: Style specific markdown elements */
.markdown-content h1, 
.markdown-content h2, 
.markdown-content h3, 
.markdown-content h4, 
.markdown-content h5, 
.markdown-content h6 {
  font-family: 'Noto Sans Mono', monospace;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: justify; /* Right justify headers */
}

.markdown-content {
  width: 100%;
  max-width: 100%; /* Ensure no parent container limits width */
}

.markdown-content p {
  margin: 1.5em 0; /* Increase margin between paragraphs */
  word-break: break-word;
}

/* Date specific styling */
.markdown-content p em:first-of-type {
  display: block;
  margin-bottom: 20px;
  color: #5e7190;
  font-style: italic;
}

/* Generic italic text styling - ensure inline display */
.markdown-content em {
  display: inline;
  color: #888;
  font-style: italic;
  margin: 0;
  padding: 0;
}

/* Default paragraph styling */
.markdown-content p {
  margin: 1.5em 0; /* Increase margin between paragraphs */
}

/* Remove margin from paragraphs containing only em elements, except for the date */
/* .markdown-content p:not(.date) {
     margin: 0;
   } */

/* Ensure em elements inside these paragraphs are inline */
.markdown-content p:not(.date) em {
  display: inline;
}

/* Specific styling for the date paragraph */
.markdown-content p.date {
  display: block;
  margin-bottom: 20px;
  color: #646464;
  font-style: italic;
}

.markdown-content pre {
  overflow: auto;
  white-space: pre-wrap; /* Allow wrapping inside code blocks */
  word-wrap: break-word;
}

@media screen and (max-width: 758px) {
  .markdown-content {
    font-family: 'Noto Sans Mono', monospace;
    font-size: 14px; 
    line-height: 1.6; 
    color: #000; 
    background-color: #ddd5cc; 
    padding: 10px; 
    border-radius: 8px; 
    text-align: justify; 
  }
  
  /* Optional: Style specific markdown elements */
  .markdown-content h1, 
  .markdown-content h2, 
  .markdown-content h3, 
  .markdown-content h4, 
  .markdown-content h5, 
  .markdown-content h6 {
    font-family: 'Noto Sans Mono', monospace;
    margin-top: 16px;
    margin-bottom: 10px;
    text-align: justify; /* Right justify headers as well */
  }
  
  .markdown-content {
    width: 100%;
    max-width: 100%; /* Ensure no parent container limits width */
  }
  
  .markdown-content p em:first-of-type {
    display: block;
    margin-bottom: 20px;
    color: #646464;
  }

  /* Ensure consistent inline styling for other italic text */
  .markdown-content em {
    display: inline;
    color: #888;
    margin: 0;
    padding: 0;
  }
}



a {
  color: #ce751b; /* Change this to your desired link color */
  text-decoration: none; /* Remove the underline */
}

/* Hover state */
a:hover {
  color: #598655; /* Darker shade for hover effect */
  text-decoration: none; /* Ensure no underline on hover */
}

/* Specific styles for links in markdown content */
.markdown-content a {
  color: #ce751b; /* Match link color to your site's theme */
  text-decoration: none; /* No underline for links */
}

/* Hover state for markdown links */
.markdown-content a:hover {
  color: #598655; /* Slightly darker color on hover */
  text-decoration: none; /* No underline on hover */
}

/* General container styling to prevent overflow */
.markdown-content .image-container {
  width: 100%;
  max-width: 100%; /* Ensure it doesn't exceed the container's width */
  text-align: center;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any overflow content */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}


.markdown-content img {
  max-width: 100%; 
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}


#image-container-1 img {
  max-width: 100%; 
  height: auto;
}

#image-container-1 .image-caption {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 12px; 
  color: #2b2b2b; /* Slightly lighter color */
  margin-top: 10px;
  text-align: center;
}

#image-container-2 img {
  max-width: 100%; 
  height: auto;
}

#image-container-2 .image-caption {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 12px; 
  color: #2b2b2b; 
  margin-top: 10px;
  text-align: center;
}

#image-container-3 img {
  max-width: 100%; 
  height: auto;
}

#image-container-3 .image-caption {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 12px; 
  color: #2b2b2b; 
  margin-top: 10px;
  text-align: center;
}

#image-container-4 img {
  max-width: 100%; 
  height: auto;
}
#image-container-4 .image-caption {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 12px; 
  color: #2b2b2b; 
  margin-top: 10px;
  text-align: center;
}

#image-container-5 img {
  max-width: 100%; 
  height: auto;
}
#image-container-5 .image-caption {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 12px; 
  color: #2b2b2b; 
  margin-top: 10px;
  text-align: center;
}

#image-container-6 img {
  max-width: 86%; 
  height: auto;
}
#image-container-6 .image-caption {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 12px; 
  color: #646464; 
  margin-top: 10px;
  text-align: center;
}