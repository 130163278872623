/* src/assets/css/header.css */
@import url('https://fonts.googleapis.com/css2?family=Bokor&family=Jacques+Francois+Shadow&family=Major+Mono+Display&family=Tiro+Kannada&display=swap');

header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #b4a89b;
  color: black;
  width: 100%;
  height: 144px;
  position: relative; /* Ensure header is relative for absolute positioning of trigger */
}

#header-img {
  height: 100px;
  width: 100px;
  margin-right: 20px;
  border: 10px solid transparent;
  border-image: linear-gradient(to bottom, #fdf5e6, #fdf5e6);
  border-image-slice: 1;
}

#header-text {
  font-size: 2em;
  font-weight: bold;
  font-family: 'Major Mono Display', monospace;
}
