/* src/assets/css/podcasts.css */

#podcasts {
    padding: 20px;
  }
  
  #podcasts h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .link-box {
    display: flex;
    align-items: flex-start; /* Align items to the start to accommodate different content heights */
    background-color: #b4a89b;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px;
    text-decoration: none;
    color: #000;
    transition: background-color 0.3s ease;
    flex-wrap: wrap; /* Allow wrapping to maintain layout integrity */
  }
  
  .link-box:hover {
    background-color: #b4a89b;
  }
  
  .thumbnail {
    flex-shrink: 0;
    margin-right: 20px;
    width: 150px; /* Adjust the width as needed */
  }
  
  .thumbnail img {
    border-radius: 8px;
    width: 100%;
    height: auto;
  }
  
  .video-description {
    flex: 1;
    font-size: 18px;
    white-space: normal; /* Allow text to wrap */
    overflow: visible; /* Ensure text is fully visible */
  }
  